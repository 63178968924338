// Benefit Items
.benefit-item {
  cursor: pointer;
}

// Workshopraum table
.workshopraum {
  table {
    width: 100%;

    tr {
      td {
        padding-bottom: 10px;
        vertical-align: top;
        &:first-child {
          font-weight: 400;
          padding-right: 30px;
        }
      }
    }
  }
}


// Pricing Table
.pricing-item {
  .pricing-icon {
    img {
      width: 60px;
      height: 60px;
      display: inline-block;
      text-align: center;
    }
  }
  .pricing-features {
    .pr-list {
      text-transform: none;
      font-weight: 300;
      font-size: 14px;
    }
  }
}

.zusatzangebote {
  .angebote-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .angebot {
      margin: 1em;
      border: 1px solid black;
      border-radius: 50%;
      overflow: hidden;
      height: 250px;

      transition: all 0.17s cubic-bezier(0, 0, 0.58, 1);

      img {
        height: 100%;
      }

      &:hover {
        transform: scale(1.04);
      }
    }
  }
}


#facilities {
  ul {
    list-style: none;

    li {
      padding: 1em;
      white-space: pre;

      i {
        padding-right: 1rem;
      }
    }
  }
}
