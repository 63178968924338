/* MIXIN */


/* Media Queries Mixin */
@mixin breakpoint($point) {
	@if $point == medium {
		@media (min-width: $medium)  { @content; }
	}
	@else if $point == large {
		@media (min-width: $large) { @content; }
    }
	@else if $point == xlarge {
		@media (min-width: $xlarge) { @content; }
    }
	@else if $point == xxlarge {
		@media (min-width: $xxlarge) { @content; }
    }
}
