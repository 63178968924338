// Contact Section
.contact-item {
  border: 3px solid $primary-color;
  padding: 20px;
  text-align: center;

  .item {
    width: 100%;
    text-align: left;
    p {
      margin: 5px 0;
      font-weight: 300;

      span {
        font-weight: 400;
        text-align: right;
        float: right;
      }
    }
  }
  &.contact-oeffnungszeiten {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
    color: white;

    a {
      color: white;

      &:hover {
        color: #ccc;
      }
    }
  }

  &.contact-sommer-wetter {
    background-color: rgba(0, 0, 0, 0.452);
  }
}

.contact-private-party span {
  display: inline-block;
  vertical-align: middle;

  width: calc(100% - 82px);
  padding-left: 5px;
}

.contact-item,
.contact-private-party {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
}

.address {
  max-width: 370px;
  margin: 0 auto;
  .contact-item {
    color: white;

    .item p {
      text-align: center;
      font-weight: 400;
    }
  }
}
