.events {
  .eventlist {
    .event-item {
      overflow: hidden;
      position: relative;
      border: 1px solid grey;
      margin-bottom: 20px;
      .event-date {
        transition: transform 0.5s;
        padding-top: 24px;
        text-align: center;
        float: left;
        width: 110px;
        .day {
          font-size: 50px;
          line-height: 45px;
        }
        .month {
        }
      }
      .event-text {
        transition: transform 0.5s;
        padding: 20px;
        margin-left: 110px;
        min-height: 113px;
        h5 {
          margin: 0;
          font-weight: 300;
        }
        p {
          margin: 0;
          font-size: 14px;
          letter-spacing: 1px;
          color: #aaa;
        }
      }
      .cat {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 100%;
        transition: transform 0.5s;
        transform: translateX(-15px);
        z-index: 5;
        > * {
          height: 100%;
          width: 100%;
        }

        .community {
          background-color: pink;
        }
        .kaffeebar {
          background-color: plum;
        }
        .coworking {
          background-color: powderblue;
        }
        .atelier {
          background-color: #e0e6b0;
        }
        .brownbag {
          background-image: linear-gradient(
                          135deg,
                          #f35657 25%,
                          #0b133c 25%,
                          #0b133c 50%,
                          #f35657 50%,
                          #f35657 75%,
                          #0b133c 75%,
                          #0b133c 100%
          );
          background-size: 22.63px 22.63px;
        }
        .colearning {
          background-color: peachpuff;
        }
        .labor {
          background-color: #0e0010;
        }
        .thesaktiv {
          background-color: #5e838d;
        }
        .werkstatt {
          background-color: #fdf8b5;
        }
        .cafephilou {
          background-color: #d4b171;
        }
        .sonntagsklub {
          background-color: #0062fe;
        }

        .half {
          height: 50%;
          &.last-child {
            top: 50%;
          }
        }
        .third {
          height: 33%;
          &:first-child {
            height: 34%;
          }
          &.second-child {
            top: 33%;
          }
          &.last-child {
            top: 66%;
          }
        }
      }
      &:hover {
        .cat {
          transform: translateX(0);
        }
        .date,
        .text {
          transform: translateX(15px);
        }
      }
    }
    a {
      text-decoration: none;
    }
  }
  .eventsingle {
    .date {
      max-width: 250px;
      border: 1px solid grey;
      padding: 20px 30px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      .weekday {
        font-weight: 300;
      }
      .day {
        padding-top: 10px;
        font-size: 70px;
        line-height: 58px;
      }

      &.striped {
        background-image: linear-gradient(
          135deg,
          #ffffff 25%,
          #d9edf7 25%,
          #d9edf7 50%,
          #ffffff 50%,
          #ffffff 75%,
          #d9edf7 75%,
          #d9edf7 100%
        );
        background-size: 56.57px 56.57px;
      }
    }

    .alert {
      font-size: 16px;
    }

    .event-detail {
      @media only screen and (max-width: 768px) {
        max-width: 250px;
        margin-left: auto;
        margin-right: auto;
      }

      p {
        padding-left: 30px;
        position: relative;
        line-height: 1;
        margin: 10px 0;

        i {
          position: absolute;
          left: 5px;
        }
      }
    }

    .form .form-control {
      font-size: 16px;
      text-transform: none !important;
      letter-spacing: normal;
      font-weight: 300;
    }

    .attendee {
      margin-bottom: 15px;
    }

    .attendee-comment {
      font-weight: 300;
      font-size: 85%;
      position: relative;
      width: 100%;
      margin-top: 5px;
      padding: 8px;
      background: #f4f4f4;
      // Not sure if Triangle is necessary?
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 10px solid #f8f8f8;
        display: block;
        z-index: 1;
        top: -10px;
        left: 20px;
      }
    }
  }

  .event-manager-close,
  .event-manager-max {
    font-weight: 300;
    font-size: 14px;
    color: #aaa;
    margin-bottom: 20px;
  }

  .event-manager-number-of-attendees {
    margin-bottom: 8px;
  }
}
