// Blog.
.blog-item-data {
  font-weight: 300;
}


.blog-item-more {
  max-width: calc(50% - 5px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.right .fa {
    float: right;
    line-height: 18px;
    margin-left: 5px;
  }
}

.blog-archive {
  font-size: 16px;
  font-weight: 300;
  color: #5f5f5f;
  line-height: 1.8;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }

  .year-anchor {
    height: 60px;

    &:first-child {
      margin-top: -60px;
    }
  }
}

// Image Caption.
.blog-item .image-caption {
  font-size: 13px;
  font-family: $font-family-sans;
  margin-top: -20px;
}
