// Font Logo Tagline
.font-tagline {
  font-weight: 400;
  text-transform: none;
  font-size: 14px;
  letter-spacing: 0;
  color: white;
}

.page-home {
  #corona {
    a.section-link {
      display: flex;
      flex-flow: row nowrap;
      text-decoration: none;

      &:hover {
        color: inherit;
      }

      .section-icon {
        flex: 0 0 max-content;
        margin: 0 3vw;

        img {
          mix-blend-mode: difference;
        }
      }

      .section-teaser {
        flex: 1;
      }
    }
  }

  #angebote {
    .list {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      .angebot {
        display: flex;
        flex-flow: column nowrap;
        width: 225px;
        margin: 0 0 60px;

        .icon {
          height: 60px;
          width: min-content;
        }

        .title {
          font-size: 1.5rem;
          text-decoration: none;
        }

        .teaser {
          flex-grow: 1;
          margin-top: 1rem;
        }

        .call-to-action {
          margin-top: 1rem;
        }
      }

    }
  }

  .blog-item-title {
    font-size: 18px;
    letter-spacing: 1px;
  }
}
