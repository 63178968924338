// Boxed Grid for Offers
//*, *:before, *:after {box-sizing:  border-box !important;}
.offers {

  .left {
    padding-right: 30px;
  }

  .right {
    padding-left: 30px;
  }

  .offers-title {
    min-height: 60px;
    padding-left: 15px;

    img {
      height: 30px;
      float: left;
    }

    span {
      display: block;
      padding-left: 40px;
      margin-top: 7px;
    }
  }

  .box-item {
    padding-bottom: 25px;
    max-width: 520px;

    .title {
      img {
        height: 30px;
        float: right;
      }

      span {
        padding-right: 5px;
      }
    }

    table {
      width: 100%;
      margin-bottom: 20px;

      tr {
        > td,
        > th {
          vertical-align: top;
          font-size: 16px;

          &:first-child {
            padding-right: 30px;
          }

          &:last-child {
            text-align: right;
          }
        }

        > th {
          font-weight: 400;
        }

        > td {
          font-weight: 300;
          font-size: 14px;
        }
      }
    }
  }
}
