b,
strong,
.font-bold, {
  font-weight: 400;
}

.bg-white {
  background-color: #fff;
}
.bg-effingergelb,
.bg-primary {
  background-color: $primary-color;
}

h1,h2,h3,h4,h5,h6{
  margin-bottom: 0.5em;
  font-weight: 300;
}

h1 a,
h1 a:hover,
h1 a:focus,
h2 a,
h2 a:hover,
h2 a:focus,
h3 a,
h3 a:hover,
h3 a:focus {
  text-decoration: none;
}

hr.effingergelb {
  background-color: #fbdb00;
  border-color: #fbdb00;
}

.hs-line-6 {
  opacity: .65;
}

.draft-title {
  display: table-cell;
  vertical-align: middle;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  .inner {
    font-family: $font-family-sans-alt;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400 !important;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: red;
    border: 2px solid red;
    padding: 2px 4px;
    margin: 2px 4px 2px 10px;
  }

  small {
    text-transform: none;
    font-size: 14px;
    font-weight: 300 !important;
    letter-spacing: normal;
  }
}

.section-text .lead:first-child {
  margin-top: 0;
}

.hyphenate {
  hyphens: auto;
  overflow-wrap: break-word;
}


// Image Cropping.
.image-crop {
  position: relative;

  // Use with an image background: style="background-image: url(...)".
  // Note: If used with an <img> tag it will only crop images height but not
  //       the images width.
  .image-crop-inner {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
  }
}

.image-crop-5by3 {
  padding-top: 60%;
}

.image-crop-3by2 {
  padding-top: 66.666666667%;
}

.image-crop-4by3 {
  padding-top: 75%;
}

.image-crop-square {
  padding-top: 100%;
}

.image-crop-2by3 {
  padding-top: 150%;
}


// Tables.
table th {
  font-weight: 400;
}

table .clickable-row {
  cursor: pointer;

  a {
    text-decoration: none;
    color: #111;
  }
}

.table-borderless tbody tr td,
.table-borderless tbody tr th,
.table-borderless thead tr th {
  border: none;
}

label {
  font-weight: normal;
}

form {
  .invalid {
    border-color: #e41919 !important;
  }

  .alert-close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
  }
}

// Font Awesome Inline instead of inline-block to avoid line-break.
.fa-inline {
  display: inline;
}

// The yellow wave spacer.
.wave-spacer {
  height: 4px;
  width: 100%;
  background-image: url(/images/wave.svg);
  background-repeat: round;
  background-size: contain;
  margin: 20px 0;
  clear: both;
}


// Navigation.
.logo img {
  height: 36px;
}

.inner-nav ul {
  font-weight: 300;
}

@media only screen and (max-width: 768px) {
  .full-wrapper {
    margin: 0 15px;
  }
}

.mn-has-sub {
  cursor: pointer;
}


// Directly set navigation and header height here instead of in js (slow).
.desktop-nav > ul > li > a,
.mobile-nav {
  height: 75px;
  line-height: 75px;
}

.mobile-nav {
  width: 75px;
}

.first-section-mt {
  margin-top: 75px;
}

.main-nav.small-height {
  .logo,
  .mobile-nav {
    height: 55px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .desktop-nav {
    display: none;
  }
}


// Set home section height in CSS to prevent flickering.
.home-section > .container {
  height: 600px;
}



// Footer.
.newsletter-arrow {
  max-width: 160px !important;
}

.footer a.btn:hover {
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .newsletter-arrow-container {
    height: 50px;
  }

  .newsletter-arrow {
    position: absolute;
    z-index: 100;
    left: 500px;
    right: 0;
    top: 150px;
    margin: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .newsletter-arrow {
    top: 130px;
  }
}

.live__icon {
  position: absolute;
  width: 50px;

  .circle--outer {
    border: 1px solid #e50040;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 auto 5px;
    position: relative;
    opacity: 0.8;
    -webkit-animation: circle 2s ease-in-out infinite;
    animation: circle 2s ease-in-out infinite;
  }

  .circle--inner {
    background: #e50040;
    left: 15px;
    top: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    opacity: 0.8;
  }

  .circle--inner:after {
    content: "";
    display: block;
    border: 2px solid #e50040;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    top: -4px;
    left: -4px;
    position: absolute;
    opacity: 0.8;
    -webkit-animation: circle 2s ease-in-out 0.2s infinite;
    animation: circle 2s ease-in-out 0.2s infinite;
  }

  p {
    color: #e50040;
    text-align: center;
    font-weight: 400;
  }

  @-webkit-keyframes circle {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.5);
      opacity: 0;
    }
  }

  @keyframes circle {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.5);
      opacity: 0;
    }
  }
}
