.finanzen {
  .monats-box {
    text-decoration: none;

    &:hover, &:focus {
      color: #111;
    }

    small {
      font-size: 14px;
    }

    .inner {
      border: 3px solid $primary-color;
      padding: 20px;

      &:hover, &:focus {
        background-color: #ececec;
      }

      @media only screen and (max-width: 767px) {
        max-width: 370px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .title {
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-top: 10px;
      text-align: center;
    }

    .item {
      margin-top: 20px;
      width: 100%;
      text-align: left;

      p {
        margin: 5px 0;
        font-weight: 300;

        .amount {
          font-weight: 400;
          text-align: right;
          float: right;

          &.total {
            border-top: 1px solid #5f5f5f;
            border-bottom: 3px double #5f5f5f;
          }
        }
      }
    }
  }

  .prev-next {
    margin-top: -43px;
  }


  .erfolg {
    justify-content: center;

    .item {
      max-width: 520px;

      .title {
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: 400;

        span {
          letter-spacing: normal;
        }
      }

      table {
        width: 100%;
        margin-bottom: 10px;

        tr {
          > td,
          > th {
            vertical-align: top;
            font-size: 16px;

            &:first-child {
              padding-right: 30px;
            }

            &:last-child {
              text-align: right;
            }
          }

          > th {
            font-weight: 400;
          }

          > td {
            font-weight: 300;
            font-size: 14px;
          }
        }
      }
    }
  }

  .chart-legend li {
    list-style: none;

    span {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
  }
}
